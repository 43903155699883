<template>
  <main style="margin: 3em;">
    <h2>アプリの使い方</h2>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/fevQ3pXsIeQ?si=7hIDdzB8LA1PEHxT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </main>
</template>


<script>


export default {
  data() {
  },
  components: {
  },
};
</script>

