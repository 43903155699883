<template>
  <main style="margin: 3em;">
    <h2>プライバシーポリシー</h2>

    <h3>1. 情報の収集</h3>
    <p style="margin-bottom: 1em;">
        「Ball Log by LilBalls」アプリ（以下、「本アプリ」）では、ログイン機能を提供するために、ユーザーのメールアドレスを収集します。それ以外の個人情報は収集いたしません。
    </p>

    <h3>2. 情報の利用目的</h3>
    <p style="margin-bottom: 1em;">
        収集したメールアドレスは、ユーザー認証およびログイン機能の提供に利用します。
    </p>

    <h3>3. 情報の共有</h3>
    <p style="margin-bottom: 1em;">
        収集したユーザーの個人情報を第三者に提供することはありません。
    </p>

    <h3>4. 情報の保護</h3>
    <p style="margin-bottom: 1em;">
        ユーザーの個人情報を適切に保護するための技術的および組織的な対策を講じています。
    </p>

    <h3>5. ユーザーの権利</h3>
    <p style="margin-bottom: 1em;">
        ユーザーは、自身のメールアドレスの確認、修正、削除を要求することができます。
    </p>

    <h3>6. 未成年者のプライバシー</h3>
    
    <p style="margin-bottom: 1em;">
        本アプリは、18歳未満のユーザーから故意に個人情報を収集することを目的としていません。
    </p>

    <h3>7. プライバシーポリシーの変更</h3>
    
    <p style="margin-bottom: 1em;">
        本プライバシーポリシーは、必要に応じて変更されることがあります。
    </p>

    <h3>8. お問い合わせ</h3>
    
    <p style="margin-bottom: 1em;">
        本プライバシーポリシーに関するお問い合わせは、以下のメールアドレスまでご連絡ください：<a href="mailto:lilballs@lilpeace.com">lilballs@lilpeace.com</a>
    </p>
  </main>
</template>


<script>


export default {
  data() {
  },
  components: {
  },
};
</script>

