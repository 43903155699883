import { createRouter, createWebHistory } from 'vue-router';
import SnakeDetail from '../components/SnakeDetail.vue';
import PrivacyPolicy from '../components/PrivacyPolicy.vue';
import HowToUseAr from '../components/HowToUseAr.vue';

const routes = [
  {
    path: '/snakes/:documentId',
    name: 'SnakeDetail',
    component: SnakeDetail,
  },
  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/how-to-use-ar',
    name: 'HowToUseAr',
    component: HowToUseAr,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
