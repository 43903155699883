<template>
  <main>
    <div class="wrapper"  v-if="snake != null">
      <div class="mv">
        <swiper
          :slides-per-view="1"
          :space-between="0"
          navigation
          :pagination="{ clickable: true }"
        >
        <swiper-slide v-for="image in snake.image_urls" :key="image">
          <img :src=image alt="ボールパイソン">
        </swiper-slide>
        </swiper>
      </div>


      <section class="bio-information">
        <div class="bio-information__inner">
          <h2 class="bio-information__name text">{{ snake?.name }}</h2>

          <div class="bio-information__container" id="js-copy-txt">
            <h3 class="bio-information__title text">生体情報</h3>
            <table class="bio-information__table">
              <tbody>
                <tr>
                  <th class="text">性別</th>
                  <td class="text">{{ snake?.gender }}</td>
                </tr>
                <tr>
                  <th class="text">誕生日</th>
                  <td class="text">{{ formatDate(snake?.birthday.seconds) }}</td>
                </tr>
                <tr>
                  <th class="text">出身</th>
                  <td class="text">{{ snake?.origin }}</td>
                </tr>
                <tr>
                  <th class="text">餌の種類と<br class="br-sp">サイズ</th>
                  <td class="text">
                    {{ latestFeedingRecord?.foods[0].name }} ({{ latestFeedingRecord?.foods[0].weight }}g)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section class="feeding">
        <div class="feeding__inner">
          <h2 class="feeding__title title">給餌</h2>

          <div class="feeding__container">
            <ul class="feeding__items">
              <li class="feeding__item" v-for="(record, index) in snake?.recent_feeding_records" :key="index">
                <time class="feeding__item__date" :datetime="formatDate(record.fed_at.seconds)">
                  {{ formatDate(record.fed_at.seconds) }}
                </time>
                <p class="feeding__item__text text">
                  {{ record.foods[0].name }} ({{ record.foods[0].weight }}g)
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="parent-info">
        <div class="parent-info__inner">
          <h2 class="parent-info__title title">親情報</h2>

            <div class="parent-info__items">
              <div class="parent-info__item">
                <p class="parent-info__item__title text">メス親</p>
                <p class="parent-info__item__title-en text">{{  snake?.mother }}</p>
              </div>
              <div class="parent-info__item">
                <p class="parent-info__item__title text">オス親</p>
                <p class="parent-info__item__title-en text">{{  snake?.father }}</p>
              </div>
            </div>
        </div>
      </section>

      <section class="clutch-mate">
          <h2 class="clutch-mate__title title">クラッチメイト</h2>

          <div class="clutch-mate__items">
            <div class="clutch-mate__item" v-for="snake in clutchMate" :key="snake.id">
              <a :href="`/snakes/${snake.id}`" class="clutch-mate__item__link">
                <img :src="snake.image_urls[0] || '@/assets/images/photo.webp'" alt="モルフ" class="clutch-mate__item__img">
                <div class="clutch-mate__item__container">
                  <h3 class="clutch-mate__item__container__name text">{{ snake.name }}</h3>
                </div>
              </a>
            </div>
          </div>
      </section>

      <!-- <div class="copy-box">
        <button id="js-copy-btn" class="copy-btn">生体情報を自分のアプリにコピーする</button>
      </div> -->
    </div>
  </main>
</template>


<script>
import { collectionGroup, collection, getDocs, query, where, limit } from "firebase/firestore";
import { db } from "../firebaseConfig";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";

SwiperCore.use([Navigation, Pagination, A11y]);


export default {
  data() {
    return {
      snake: null,
      clutchMate: []
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    latestFeedingRecord() {
      // recent_feeding_records 配列の最後の要素を取得
      return this.snake?.recent_feeding_records[this.snake?.recent_feeding_records.length - 1];
    },
  },
  async created() {
    const documentId = this.$route.params.documentId;

    try {
      const snakesQuery = query(
        collectionGroup(db, "snakes"),
        where("snake_id", "==", documentId),  // documentIdに対応するsnakeIdをクエリに使用
        limit(1)
      );
      const querySnapshot = await getDocs(snakesQuery);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        this.snake = { id: doc.id, ...doc.data() };


        const userDocumentId = doc.ref.path.split('/')[1];
        const clutchId = this.snake.clutch_id;
        const relatedSnakesQuery = query(
          collection(db, `users/${userDocumentId}/snakes`),
          where("clutch_id", "==", clutchId)
        );

        const clutchMateQuerySnapshot = await getDocs(relatedSnakesQuery);

        this.clutchMate = clutchMateQuerySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
      } else {
        console.log("No document found!");
      }


    } catch (error) {
      console.error("Error fetching document: ", error);
    }
  },
  methods: {
    formatDate(seconds) {
      const date = new Date(seconds * 1000);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}/${month}/${day}`;
    },
  },
};
</script>

